<template>
  <Breadcrumb />
  <div v-if="selectedTabValue == 'system'" class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t(
          "Configurations.NewSetxrmConfiguration",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link
    >
  </div>
  <ul class="nav nav-tabs mb-2" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        @click="selectedTab('system')"
        data-bs-toggle="pill"
        data-bs-target="#pills-setxrm-tab"
        type="button"
        role="tab"
        aria-selected="true"
      >
        {{
          $t(
            "Configurations.TypeSetXRM",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        @click="selectedTab('specialsetxrm')"
        data-bs-toggle="pill"
        data-bs-target="#pills-setxrm-tab-2"
        type="button"
        role="tab"
        aria-selected="false"
      >
        {{
          $t(
            "Configurations.TypeAllSetxrm",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        @click="selectedTab('specialcustom')"
        data-bs-toggle="pill"
        data-bs-target="#pills-custom-tab"
        type="button"
        role="tab"
        aria-selected="false"
      >
        {{
          $t(
            "Configurations.TypeCustom",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </li>
  </ul>
  <div class="tab-content">
    <div
      class="tab-pane fade active show"
      id="pills-setxrm-tab"
      role="tabpanel"
    >
      <Grid :settings="gridConfigurationListSettings" :columns="gridColumns" />
    </div>
    <div class="tab-pane fade setxrm" id="pills-setxrm-tab-2" role="tabpanel">
      <Grid
        :settings="gridAllSetXrmConfigurationListSettings"
        :columns="gridColumns"
      />
    </div>
    <div class="tab-pane fade" id="pills-custom-tab" role="tabpanel">
      <Grid
        :settings="gridCustomConfigurationListSettings"
        :columns="gridColumns"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfigurationList",
  components: {},
  data() {
    return {
      gridConfigurationListSettings: {
        action: "ConfigurationList",
        requestUrl: String.format("/Lcdp-DefaultConfigurationList"),

        isCommandColumnHide: true,
        allowSearchPanel: true,
        allowPaging: true,
        autoOrderProcess: true,
        isGatewayRequest: true,
      },
      gridAllSetXrmConfigurationListSettings: {
        action: "AllSetXrmConfigurationList",
        requestUrl: String.format(
          "/Lcdp-ConfigurationListAllCustom?configurationTypeID=1&companyId=1"
        ),

        isCommandColumnHide: true,
        allowSearchPanel: true,
        allowPaging: true,
        autoOrderProcess: true,
        isGatewayRequest: true,
      },
      gridCustomConfigurationListSettings: {
        action: "CustomConfigurationList",
        requestUrl: String.format(
          "/Lcdp-ConfigurationListAllCustom?configurationTypeID=2&companyId=1"
        ),

        isCommandColumnHide: true,
        allowSearchPanel: true,
        allowPaging: true,
        autoOrderProcess: true,
        isGatewayRequest: true,
      },
      gridColumns: [
        {
          text: this.$t(
            "Configurations.CompanyId",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "companyId",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Configurations.CompanyName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "companyName",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Configurations.Key",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "key",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Configurations.Value",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "value",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Configurations.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
      selectedTabValue: "system",
    };
  },
  methods: {
    selectedTab(tab) {
      this.selectedTabValue = tab;
    },
  },
  mounted() {},
};
</script>
